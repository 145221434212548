import React from 'react'
import { types, Text } from 'react-bricks/frontend'
import blockNames from '../../blockNames'
interface SubMenuHeaderItemProps {
  title: types.TextValue
}

const SubMenuHeaderItem: types.Brick<SubMenuHeaderItemProps> = ({ title }) => {
  return (
    <Text
      propName="title"
      value={title}
      placeholder="Type a title..."
      renderBlock={({ children }) => <h1>{children}</h1>}
    />
  )
}

SubMenuHeaderItem.schema = {
  // Unique brick name
  name: blockNames.SubMenuHeaderItem,
  label: 'Sub Menu Header Item',

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    title: 'Thick as a brick',
  }),

  // Sidebar Edit controls for props
  sideEditProps: [],
}

export default SubMenuHeaderItem
