import React from 'react'
import { types } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import clsx from 'clsx'
interface DividerProps {
  spacing: 'none' | 'small' | 'medium' | 'large'
}

const Divider: types.Brick<DividerProps> = ({ spacing }) => {
  const spacingClass = {
    small: 'small',
    medium: '',
    large: 'large',
  }[spacing]
  return (
    <hr
      className={clsx(
        'col-12',
        spacing !== 'none' && 'divider-spacing ' + spacingClass,
      )}
    />
  )
}

Divider.schema = {
  // Unique brick name
  name: blockNames.Divider,
  label: 'Divider',

  // Defaults when a new brick is added
  getDefaultProps: () => ({ spacing: 'medium' }),

  // Sidebar Edit controls for props
  sideEditProps: [
    {
      name: 'spacing',
      label: 'Spacing',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { value: 'none', label: 'None' },
          { value: 'small', label: 'Small' },
          { value: 'medium', label: 'Medium' },
          { value: 'large', label: 'Large' },
        ],
      },
    },
  ],
}

export default Divider
