import React from 'react'
import { types, Text } from 'react-bricks/frontend'
import blockNames from '../../blockNames'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

interface FormFieldProps {
  label: types.TextValue
  type: string
  showLabel: boolean
  placeholder: string
  helpText: types.TextValue
}

const FormField: types.Brick<FormFieldProps> = ({
  type,
  showLabel,
  placeholder,
  label,
  helpText,
}) => {
  const id = nanoid()
  return (
    <div className="mb-2" id={`${id}`}>
      <label
        className={classNames({ 'visually-hidden': !showLabel })}
        id={`${id}-label`}
      >
        <Text
          propName="label"
          placeholder="Type a label..."
          renderBlock={({ children }) => <p className="mb-0">{children}</p>}
          renderPlaceholder={() => <p className="mb-0">Type a label...</p>}
        />
      </label>
      <input
        type={type}
        placeholder={placeholder}
        id={`${id}`}
        aria-describedby={`${id}-help`}
        className="form-control"
      />
      <div id={`${id}-help`} className="form-text">
        <Text
          propName="helpText"
          placeholder="Type a help text..."
          renderBlock={({ children }) => <p className="mb-0">{children}</p>}
          renderPlaceholder={() => <p className="mb-0">Type a help text...</p>}
        />
      </div>
    </div>
  )
}

FormField.schema = {
  // Unique brick name
  name: blockNames.FormField,
  label: 'Form Field',

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    showLabel: true,
    type: 'text',
    label: 'Text Field',
    placeholder: 'Type a placeholder in the sidebar',
    helpText: 'Type a help text in the sidebar',
  }),

  // Sidebar Edit controls for props
  sideEditProps: [
    {
      name: 'showLabel',
      label: 'Show Label',
      type: types.SideEditPropType.Boolean,
    },

    {
      name: 'placeholder',
      label: 'Placeholder',
      type: types.SideEditPropType.Text,
    },
    {
      name: 'type',
      label: 'Type',
      type: types.SideEditPropType.Select,
      selectOptions: {
        options: [
          { value: 'text', label: 'Text' },
          { value: 'email', label: 'Email' },
          { value: 'password', label: 'Password' },
          { value: 'number', label: 'Number' },
          { value: 'tel', label: 'Tel' },
          { value: 'url', label: 'Url' },
        ],
        display: types.OptionsDisplay.Select,
      },
    },
  ],
}

export default FormField
