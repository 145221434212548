import React from 'react'
import { types, RichText } from 'react-bricks/frontend'
import blockNames from '../blockNames'
interface ParagraphProps {
  title: types.TextValue
}

const Paragraph: types.Brick<ParagraphProps> = ({ title }) => {
  return (
    <section className="container">
      <RichText propName="richText" />
    </section>
  )
}

Paragraph.schema = {
  name: blockNames.Paragraph,
  label: 'Paragraph',
  sideEditProps: [],
}

export default Paragraph
