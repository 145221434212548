import React from 'react'
import { types, Text, Repeater } from 'react-bricks/frontend'
import blockNames from '../../blockNames'

interface FormProps {
  title: types.TextValue
}

const Form: types.Brick<FormProps> = ({ title }) => {
  return (
    <form>
      <Text
        propName="title"
        value={title}
        placeholder="Type a title..."
        renderBlock={({ children }) => <p className="h4 mb-3">{children}</p>}
      />
      <div>
        <Repeater propName="formFields" />
      </div>
      <div className="mt-3 d-flex gap-2">
        <Repeater
          propName="submitButton"
          itemProps={{ type: 'submit', title: 'Submit' }}
        />
        <Repeater
          propName="clearButton"
          itemProps={{
            type: 'reset',
            title: 'Clear',
            variant: 'outline-dark',
          }}
        />
      </div>
    </form>
  )
}
Form.schema = {
  // Unique brick name
  name: blockNames.Form,
  label: 'Form',

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    title: 'Form',
    submitButton: [
      {
        type: 'submit',
        title: 'Submit',
        variant: 'primary',
      },
    ],
    clearButton: [
      {
        type: 'reset',
        title: 'Clear',
        variant: 'outline-dark',
      },
    ],
  }),

  // Sidebar Edit controls for props
  sideEditProps: [],

  // Repeaters
  repeaterItems: [
    {
      name: 'formFields',
      itemType: blockNames.FormField,
      itemLabel: 'Form Field',
      min: 1,
    },
    {
      name: 'submitButton',
      itemType: blockNames.Button,
      itemLabel: 'Form Submit Button',
      min: 0,
      max: 1,
    },
    {
      name: 'clearButton',
      itemType: blockNames.Button,
      itemLabel: 'Form Clear Button',
      min: 0,
      max: 1,
    },
  ],
}

export default Form
