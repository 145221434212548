import React from 'react'
import { types, Text, Image } from 'react-bricks/frontend'
import bsBlockNames from '../blockNames'

interface TextImageProps {
  title: types.TextValue
  callout: types.TextValue
  description: types.TextValue
  blockImage: types.IImageSource
  imagePosition: 'left' | 'right'
}

const TextImage: types.Brick<TextImageProps> = ({
  title,
  callout,
  description,
  blockImage,
  imagePosition,
}) => {
  return (
    <section className="section-spacing">
      <div className="container">
        <div
          className={`row g-3 g-lg-5 ${imagePosition === 'left' ? 'flex-row-reverse' : ''}`}
        >
          <div className="col-lg-6">
            <div className="d-flex flex-column justify-content-end h-100 pb-lg-5">
              <div className="d-flex flex-column flex-column-reverse">
                <Text
                  propName="title"
                  value={title}
                  placeholder="Type a title..."
                  renderPlaceholder={() => <span>Type a title...</span>}
                  renderBlock={({ children }) => (
                    <h2 className="">{children}</h2>
                  )}
                />
                <Text
                  propName="callout"
                  value={callout}
                  placeholder="Type a callout..."
                  renderPlaceholder={() => <span>Type a callout...</span>}
                  renderBlock={({ children }) => (
                    <div>
                      <h3 className="h5 bg-light-primary text-primary badge rounded-pill px-3 py-2 mb-3 text-nowrap">
                        {children}
                      </h3>
                    </div>
                  )}
                />
              </div>
              <Text
                propName="description"
                value={description}
                placeholder="Type a description..."
                renderPlaceholder={() => <span>Type a description...</span>}
                renderBlock={({ children }) => (
                  <p className="lead">{children}</p>
                )}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <Image
              propName="blockImage"
              alt={blockImage?.alt}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              aspectRatio={4 / 3}
              imageClassName="img-fluid rounded-3 shadow-lg"
              maxWidth={1200}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

TextImage.schema = {
  // Unique brick name
  name: bsBlockNames.TextImage,
  label: 'Text Image Block',

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    title: 'Thick as a brick',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    imagePosition: 'right',
  }),

  // Sidebar Edit controls for props
  sideEditProps: [
    {
      name: 'blockImage',
      label: 'Block Image',
      type: types.SideEditPropType.Image,
    },
    {
      name: 'imagePosition',
      label: 'Image Position',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' },
        ],
      },
    },
  ],
}

export default TextImage
