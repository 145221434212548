import { navigate } from 'gatsby'
import { types } from 'react-bricks/frontend'

import bricks from './bricks'
import pageTypes from './pageTypes'
import GatsbyLink from './GatsbyLink'

const config: types.ReactBricksConfig = {
  appId: process.env.GATSBY_APP_ID + '',
  apiKey: process.env.API_KEY + '',
  environment: process.env.GATSBY_PUBLIC_ENVIRONMENT,
  loginUI: {
    logo: '/logo-hq.svg',
    welcomeText: 'SellingHouseBrokers.com',
    welcomeTextStyle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginTop: '2rem',
      padding: '0.5rem 1rem',
      textAlign: 'center',
      borderRadius: '8px',
      color: '#FF0066',
      fontFamily: 'Inter',
      display: 'inline-block',
      border: '1px solid #FF0066',
    },
  },
  pageTypes,
  bricks,
  logo: '/logo-hq.svg',
  previewPath: '/preview',
  isDarkColorMode: false,
  defaultTheme: 'Bootstrap 5',
  renderLocalLink: GatsbyLink,
  navigate,
  loginPath: '/admin',
  editorPath: '/admin/editor',
  playgroundPath: '/admin/playground',
  appSettingsPath: '/admin/app-settings',
  useCssInJs: false,
  appRootElement: '#___gatsby',
  clickToEditSide: types.ClickToEditSide.BottomRight,
  customFields: [],
  responsiveBreakpoints: [
    { type: types.DeviceType.Phone, width: 480, label: 'Smartphone' },
    { type: types.DeviceType.Tablet, width: 768, label: 'Tablet' },
    { type: types.DeviceType.Desktop, width: '100%', label: 'Desktop' },
  ],
  enableAutoSave: true,
  disableSaveIfInvalidProps: true,
  enablePreview: true,
  enablePreviewImage: true,
}

export default config
