import React from 'react'
import { types, Text, Image } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import * as styles from '../_styles/KeyFeatureItem.module.css'
import clsx from 'clsx'
interface KeyFeatureItemProps {
  title: types.TextValue
  icon: types.IImageSource
  description: types.TextValue
}

const KeyFeatureItem: types.Brick<KeyFeatureItemProps> = ({
  title,
  icon,
  description,
}) => {
  return (
    <div className="col-12 col-md-6 col-xl-3">
      <div className={clsx(styles.topContainer)}>
        <Image
          propName="icon"
          alt={icon?.alt}
          source={icon}
          aspectRatio={1}
          imageClassName={clsx(styles.icon)}
          quality={100}
        />
        <Text
          propName="title"
          value={title}
          placeholder="Type a title..."
          renderBlock={({ children }) => (
            <h4 className={clsx(styles.topContainerHeading, 'h5')}>
              {children}
            </h4>
          )}
          renderPlaceholder={() => <span>Type a title...</span>}
        />
      </div>
      <div className={clsx(styles.bottomContainer)}>
        <Text
          propName="description"
          value={description}
          placeholder="Type a description..."
          renderBlock={({ children }) => (
            <p className={clsx(styles.description)}>{children}</p>
          )}
          renderPlaceholder={() => <span>Type a description...</span>}
        />
      </div>
    </div>
  )
}

KeyFeatureItem.schema = {
  // Unique brick name
  name: blockNames.KeyFeatureItem,
  label: 'Key Feature Item',

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    title: 'Thick as a brick',
  }),

  // Sidebar Edit controls for props
  sideEditProps: [
    {
      name: 'icon',
      label: 'Icon',
      type: types.SideEditPropType.Image,
      helperText: 'Try to use an icon',
    },
  ],
}

export default KeyFeatureItem
