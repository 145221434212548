import React from 'react'
import { types, Text, Repeater } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import Divider from '../Divider'

interface KeyFeaturesProps {
  callout: types.TextValue
  title: types.TextValue
}

const KeyFeatures: types.Brick<KeyFeaturesProps> = ({ callout, title }) => {
  return (
    <section className="section-spacing">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-7 mx-auto">
            <div className="d-flex flex-column align-items-start align-items-lg-center mx-auto flex-column-reverse">
              <Text
                propName="title"
                value={title}
                placeholder="Type a section heading..."
                renderBlock={({ children }) => (
                  <h2 className="text-left text-lg-center display-5 fw-medium mb-0">
                    {children}
                  </h2>
                )}
                renderPlaceholder={() => <span>Type a section heading...</span>}
              />
              <Text
                propName="callout"
                placeholder="Type a callout..."
                renderBlock={({ children }) => (
                  <h3 className="h5 bg-light-primary text-primary badge rounded-pill px-3 py-2 mb-3">
                    {children}
                  </h3>
                )}
                renderPlaceholder={() => <span>Type a callout...</span>}
              />
            </div>
          </div>
        </div>
        <Divider spacing="medium" />
        <div className="row g-2 g-md-5">
          <Repeater propName="keyFeatureItems" />
        </div>
      </div>
    </section>
  )
}

KeyFeatures.schema = {
  // Unique brick name
  name: blockNames.KeyFeatures,
  label: 'Key Features',

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    title: 'Key Features',
  }),

  // Sidebar Edit controls for props
  sideEditProps: [],

  // Repeater props
  repeaterItems: [
    {
      name: 'keyFeatureItems',
      itemType: blockNames.KeyFeatureItem,
      itemLabel: 'Key Feature Item',
      min: 1,
      max: 8,
    },
  ],
}

export default KeyFeatures
