import React from 'react'
import { types, Text, Link } from 'react-bricks/frontend'
import blockNames from '../../blockNames'
import { useLocation } from '@reach/router'
import * as styles from '../../_styles/HeaderMenuItem.module.css'

interface MenuHeaderItemProps {
  title: types.TextValue
  href: string
  linkType: 'internal' | 'external'
  openInNewTab: boolean
}

const MenuHeaderItem: types.Brick<MenuHeaderItemProps> = ({
  title,
  href,
  linkType,
  openInNewTab,
}) => {
  const LinkTag = linkType === 'internal' ? Link : 'a'
  const location = useLocation()

  return (
    <li className="nav-item">
      <Text
        propName="title"
        value={title}
        placeholder="Type a title..."
        renderPlaceholder={({ children }) => <span>{children}</span>}
        renderBlock={({ children }) => (
          <LinkTag
            className={`nav-link ${location.pathname === href ? 'active' : ''}`}
            aria-current={location.pathname === href ? 'page' : undefined}
            href={href}
            target={openInNewTab ? '_blank' : '_self'}
          >
            {children}
          </LinkTag>
        )}
      />
    </li>
  )
}

MenuHeaderItem.schema = {
  // Unique brick name
  name: blockNames.MenuHeaderItem,
  label: 'Menu Header Item',

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    href: '#',
    linkType: 'internal',
    openInNewTab: false,
  }),

  // Sidebar Edit controls for props
  sideEditProps: [
    {
      name: 'linkType',
      label: 'Link Type',
      type: types.SideEditPropType.Select,
      selectOptions: {
        options: [
          { value: 'internal', label: 'Internal' },
          { value: 'external', label: 'External' },
        ],
        display: types.OptionsDisplay.Radio,
      },
    },
    {
      name: 'openInNewTab',
      label: 'Open in new tab',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'href',
      label: 'Href',
      type: types.SideEditPropType.Text,
    },
  ],
}

export default MenuHeaderItem
