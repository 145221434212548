import React from 'react'
import { types, Text, Link, useAdminContext } from 'react-bricks/frontend'
import blockNames from '../../blockNames'
import clsx from 'clsx'

interface ButtonProps {
  title: string
  type?: 'button' | 'submit' | 'reset' | 'link'
  variant?: string
  size?: 'sm' | 'lg' | 'md'
  active?: boolean
  disabled?: boolean
  href?: string
  isTargetBlank?: boolean
  classNames?: string
}

const Button: types.Brick<ButtonProps> = ({
  title,
  type = 'button',
  variant = 'primary',
  size = 'md',
  active = false,
  disabled = false,
  href,
  isTargetBlank = false,
  classNames,
}) => {
  const { isAdmin, previewMode } = useAdminContext()
  const isLink = type === 'link'
  const Tag = isLink ? (Link as any) : 'button'

  const commonProps = {
    className: clsx(`btn btn-${variant}`, `btn-${size}`, classNames, { active, disabled }),
    ...(isLink && { href, target: isTargetBlank ? '_blank' : '_self' }),
    ...(isAdmin && !previewMode && { type: 'button' }),
  }

  return (
    <Tag {...commonProps}>
      <Text
        propName="title"
        value={title}
        renderBlock={({ children }) => <>{children}</>}
        renderPlaceholder={() => <span>Button Title...</span>}
      />
    </Tag>
  )
}

Button.schema = {
  name: blockNames.Button,
  label: 'Button',
  category: 'Layout',
  getDefaultProps: () => ({
    title: 'Button',
    variant: 'primary',
    type: 'button',
    size: 'md',
    href: '#',
    isTargetBlank: false,
  }),
  sideEditProps: [
    {
      name: 'variant',
      label: 'Variant',
      type: types.SideEditPropType.Select,
      selectOptions: {
        options: [
          { value: 'primary', label: 'Primary' },
          { value: 'secondary', label: 'Secondary' },
          { value: 'success', label: 'Success' },
          { value: 'danger', label: 'Danger' },
          { value: 'warning', label: 'Warning' },
          { value: 'info', label: 'Info' },
          { value: 'light', label: 'Light' },
          { value: 'dark', label: 'Dark' },
          { value: 'link', label: 'Link' },
          { value: 'outline-primary', label: 'Outline Primary' },
          { value: 'outline-secondary', label: 'Outline Secondary' },
          { value: 'outline-success', label: 'Outline Success' },
          { value: 'outline-danger', label: 'Outline Danger' },
          { value: 'outline-warning', label: 'Outline Warning' },
          { value: 'outline-info', label: 'Outline Info' },
          { value: 'outline-light', label: 'Outline Light' },
          { value: 'outline-dark', label: 'Outline Dark' },
        ],
        display: types.OptionsDisplay.Select,
      },
    },
    {
      name: 'type',
      label: 'Type',
      type: types.SideEditPropType.Select,
      selectOptions: {
        options: [
          { value: 'button', label: 'Button' },
          { value: 'submit', label: 'Submit' },
          { value: 'reset', label: 'Reset' },
          { value: 'link', label: 'Link' },
        ],
        display: types.OptionsDisplay.Radio,
      },
    },
    {
      name: 'href',
      label: 'Link',
      type: types.SideEditPropType.Text,
      show: (props) => props.type === 'link',
    },
    {
      name: 'isTargetBlank',
      label: 'Open in new tab',
      type: types.SideEditPropType.Boolean,
      show: (props) => props.type === 'link',
    },
    {
      name: 'size',
      label: 'Size',
      type: types.SideEditPropType.Select,
      selectOptions: {
        options: [
          { value: 'sm', label: 'Small' },
          { value: 'md', label: 'Medium' },
          { value: 'lg', label: 'Large' },
        ],
        display: types.OptionsDisplay.Radio,
      },
    },
  ],
}

export default Button
