import React from 'react'
import { types } from 'react-bricks/frontend'
import blockNames from '../../blockNames'
import TopBar from './TopBar'
import NavBar from './NavBar'
import * as HeaderStyles from '../../_styles/Header.module.css'

interface HeaderProps {
  email: types.TextValue
  phoneNumber: types.TextValue
}

const Header: types.Brick<HeaderProps> = ({ email, phoneNumber }) => {
  return (
    <nav>
      <TopBar phoneNumber={phoneNumber} email={email} />
      <NavBar />
    </nav>
  )
}

Header.schema = {
  // Unique brick name
  name: 'header',
  label: 'Header',

  // Defaults when a new brick is added
  getDefaultProps: () => ({}),

  // Sidebar Edit controls for props
  sideEditProps: [],

  // Repeater props
  repeaterItems: [
    {
      name: 'menuHeaderItems',
      itemType: blockNames.MenuHeaderItem,
      itemLabel: 'Menu Items',
    },
  ],
}

export default Header
