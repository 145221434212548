enum blockNames {
  HeroBlock = 'heroBlock',
  Paragraph = 'paragraph',
  Button = 'button',
  Form = 'form',
  FormField = 'formField',
  Header = 'header',
  Footer = 'footer',
  MenuHeaderItem = 'menuHeaderItem',
  SubMenuHeaderItem = 'subMenuHeaderItem',
  KeyFeatures = 'keyFeatures',
  KeyFeatureItem = 'keyFeatureItem',
  TextImage = 'textImageBlock',
  Divider = 'dividerBlock',
}

export default blockNames
