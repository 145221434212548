import React from 'react'
import * as HeaderStyles from '../../_styles/Header.module.css'
import clsx from 'classnames'
import { types, Text, Link, Plain } from 'react-bricks/frontend'
import { phone } from 'phone'

interface TopBarProps {
  phoneNumber: types.TextValue
  email: types.TextValue
}

function TopBar({ phoneNumber, email }: TopBarProps) {
  const emailValue = Plain.serialize(email as any)
  const phoneNumberValue = phone(
    Plain.serialize(phoneNumber as any)
  ).phoneNumber

  return (
    <div className={clsx(HeaderStyles.topBar, 'border-bottom py-2')}>
      <div className="container-fluid">
        <div className="d-flex flex-row gap-3 gap-md-2 justify-content-end">
          <span className="text-muted flex-nowrap d-flex">
            <span className="d-none d-md-inline">
              Get a cash offer now!&nbsp;
            </span>
            <Link
              href={`tel:${phoneNumberValue}`}
              className={HeaderStyles.topBarLink}
            >
              <Text
                propName="phoneNumber"
                placeholder="Type your phone number..."
                renderPlaceholder={({ children }) => <span>{children}</span>}
                renderBlock={({ children }) => <span>{children}</span>}
              />
            </Link>
          </span>
          <span>|</span>
          <Link
            href={`mailto:${emailValue}`}
            className={HeaderStyles.topBarLink}
          >
            <Text
              propName="email"
              placeholder="Type your email..."
              renderPlaceholder={({ children }) => <span>{children}</span>}
              renderBlock={({ children }) => (
                <>
                  <span className="d-md-none">Email Us</span>
                  <span className="d-none d-md-inline">{children}</span>
                </>
              )}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TopBar
