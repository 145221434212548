import React from 'react'
import { types, Text, Repeater } from 'react-bricks/frontend'
import blockNames from '../blockNames'
import clsx from 'clsx'
import * as HeroBlockStyles from '../_styles/HeroBlock.module.css'

interface HeroBlockProps {
  title: types.TextValue
  description: types.TextValue
  backgroundImage: types.IImageSource
}

const HeroBlock: types.Brick<HeroBlockProps> = ({
  title,
  description,
  backgroundImage,
}) => {
  return (
    <section className={clsx('bg-light', 'position-relative')}>
      <div
        className={clsx(HeroBlockStyles.backgroundImage, {
          'd-none': !backgroundImage,
        })}
        style={{
          backgroundImage: `url(${backgroundImage?.src})`,
        }}
      ></div>
      <div className={clsx('container', HeroBlockStyles.container)}>
        <div className="row g-4">
          <div className="col-lg-6 pe-md-4 pe-lg-5">
            <Text
              propName="title"
              value={title}
              placeholder="Type a title..."
              renderBlock={({ children }) => (
                <h1 className="display-3 fw-bold">{children}</h1>
              )}
            />
            <Text
              propName="description"
              value={description}
              placeholder="Type a description..."
              renderBlock={({ children }) => <p>{children}</p>}
            />
            <Repeater
              propName="buttons"
              renderWrapper={(children) => (
                <div className="d-grid d-xl-flex gap-2 mt-4">{children}</div>
              )}
              // renderItemWrapper={(children) => (
              //   <div className={HeroBlockStyles.button}>{children}</div>
              // )}
            />
          </div>
          <div className="col-lg-6">
            <div
              className={clsx(
                HeroBlockStyles.content,
                'bg-white p-3 p-lg-4 rounded shadow'
              )}
            >
              <Repeater propName="content" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

HeroBlock.schema = {
  // Unique brick name
  name: blockNames.HeroBlock,
  label: 'Hero Block',

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    title: 'Thick as a brick',
    description: 'Thick as a brick',
    buttons: [
      {
        title: 'Button 1',
        variant: 'primary',
        type: 'button',
        href: '',
        size: 'md',
        isTargetBlank: false,
      },
      {
        title: 'Button 2',
        variant: 'outline-dark',
        type: 'button',
        size: 'md',
        href: '',
        isTargetBlank: false,
      },
    ],
  }),

  // Sidebar Edit controls for props
  sideEditProps: [
    {
      name: 'backgroundImage',
      label: 'Background Image',
      type: types.SideEditPropType.Image,
    },
  ],

  // Repeater
  repeaterItems: [
    {
      name: 'buttons',
      itemType: blockNames.Button,
      itemLabel: 'Button',
      min: 0,
      max: 2,
    },
    {
      name: 'content',
      itemType: blockNames.Form,
      itemLabel: 'Form',
      min: 0,
      max: 1,
    },
  ],
}

export default HeroBlock
