import { types } from 'react-bricks/frontend'
// Bricks
import HeroBlock from './HeroBlock'
import Paragraph from './Paragraph'
import Button from './_shared/Button'
import Form from './_shared/Form'
import FormField from './_shared/Form/FormField'
import Header from './_layout/Header'
import Footer from './_layout/Footer'
import MenuHeaderItem from './_layout/Header/MenuHeaderItem'
import SubMenuHeaderItem from './_layout/Header/SubMenuHeaderItem'
import KeyFeatures from './KeyFeatures'
import KeyFeatureItem from './KeyFeatures/KeyFeatureItem'
import TextImage from './TextImage'
import Divider from './Divider'
// Theme structure
const allBricks: types.Theme = {
  themeName: 'Bootstrap 5',
  categories: [
    {
      categoryName: 'Hero',
      bricks: [HeroBlock],
    },
    {
      categoryName: 'Main',
      bricks: [Paragraph, KeyFeatures, KeyFeatureItem, TextImage],
    },
    {
      categoryName: 'Layout',
      bricks: [Header, Footer, MenuHeaderItem, SubMenuHeaderItem, Button, Divider],
    },
    {
      categoryName: 'Form',
      bricks: [Form, FormField],
    },
  ],
}

export { HeroBlock, Paragraph, KeyFeatures, KeyFeatureItem, Form, FormField }

export default allBricks
