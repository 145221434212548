import React from 'react'
import { types, Text } from 'react-bricks/frontend'
import blockNames from '../../blockNames'
interface FooterProps {
  title: types.TextValue
}

const Footer: types.Brick<FooterProps> = ({ title }) => {
  return (
    <div>
      <Text
        propName="title"
        value={title}
        placeholder="Type a title..."
        renderBlock={({ children }) => <h1>{children}</h1>}
      />
    </div>
  )
}

Footer.schema = {
  // Unique brick name
  name: blockNames.Footer,
  label: 'Footer',

  // Defaults when a new brick is added
  getDefaultProps: () => ({
    title: 'Thick as a brick',
  }),

  // Sidebar Edit controls for props
  sideEditProps: [],
}

export default Footer
