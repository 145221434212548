import { types } from 'react-bricks/frontend'
import blogPostTags from './blogPostTags'
import bsBlockNames from './bricks/bootstrap5/blockNames'

const pageTypes: types.IPageType[] = [
  {
    name: 'page',
    pluralName: 'pages',
    defaultLocked: false,
    defaultStatus: types.PageStatus.Published,
    allowedBlockTypes: [
      bsBlockNames.HeroBlock,
      bsBlockNames.Paragraph,
      bsBlockNames.KeyFeatures,
      bsBlockNames.TextImage,
      bsBlockNames.Divider,
    ],
  },
  {
    name: 'blog_post',
    pluralName: 'Blog Posts',
    defaultStatus: types.PageStatus.Published,
    customFields: [
      {
        name: 'postCategory',
        label: 'Post Category',
        type: types.SideEditPropType.Select,
        selectOptions: {
          display: types.OptionsDisplay.Select,
          options: blogPostTags,
        },
      },
      {
        name: 'readingTime',
        label: 'Reading Time (minutes)',
        type: types.SideEditPropType.Number,
        validate: (value) => value >= 5,
        rangeOptions: {
          min: 1,
          step: 1,
        },
      },
      {
        name: 'featuredPost',
        label: 'Featured Post',
        type: types.SideEditPropType.Boolean,
      },
      {
        name: 'contentSummary',
        label: 'Content Summary',
        type: types.SideEditPropType.Textarea,
      },
    ],
  },
  {
    name: 'layout',
    pluralName: 'layout',
    defaultStatus: types.PageStatus.Published,
    isEntity: true,
    allowedBlockTypes: [bsBlockNames.Header, bsBlockNames.Footer],
  },
]

export default pageTypes
